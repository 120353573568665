import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const HeaderBackgroundSection = ({ className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "simple_succulent.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const imageData = data.background.childImageSharp.fluid

  return (
    <BackgroundImage
      className={className}
      fluid={imageData}
    >
        {children}
    </BackgroundImage>
  )
}

const HeaderBackground = styled(HeaderBackgroundSection)`
  width: 100%;
  background-size: cover;
  background-position: center;
`

export default HeaderBackground